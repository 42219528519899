@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.memberList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.memberItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  &.dashboard {
    max-width: 452px;
  }
}

.name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex-basis: 45%;
}

.access {
  @include font-size($gl-font-size-base);
  text-transform: capitalize;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 70px;
}

.appAccessLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
