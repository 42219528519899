@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.info {
  @include font-size($gl-font-size-base);
  line-height: 1.5;
  white-space: pre-wrap;
  color: $clr-black-medium;
  font-weight: $gl-font-weight-regular;
  max-width: $gl-subsection-max-width;

  a {
    font-size: inherit;
  }

  &.newApp {
    max-width: 500px;
  }
}
