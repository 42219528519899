@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.background {
  @include font-size($gl-font-size-l);
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: $clr-white;
  font-weight: $gl-font-weight-medium;

  &.dashed {
    border: 1px dashed $clr-primary-500;
  }
}
