@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.section {
  @include phone {
    padding: 12px 0 0 0;

    &.hideHeader {
      padding: 0;
    }
  }
}

.header {
  padding: 36px 40px;
  border-bottom: 1px solid #e2dfdd;
  width: 100%;

  &.withTabAndSelect {
    padding: 36px 40px 0;
    border-bottom: 2px solid #ebecef;

    @include mobile {
      border-bottom: 1px solid #e2dfdd;
    }

    @include phone {
      padding: 0 16px 16px;
    }
  }

  &.withTab {
    padding: 36px 40px 0;
    border-bottom: 2px solid #ebecef;
  }

  @include phone {
    padding: 0 12px 12px;

    &.paddingBottom {
      padding: 4px 16px 22px;
    }
  }
}

.headerContent {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-width: $gl-accordion-max-width;
  width: 100%;

  @media (min-width: $gl-breakpoint-webflow-desktop) {
    margin: 0 auto;
  }
}

.headerTitle {
  @include font-size($gl-font-size-3xl);
  font-family: "HKgrotesk";
  align-items: left;
  justify-content: flex-start;
  display: flex;
  font-weight: $gl-font-weight-bold;
  color: $clr-black;
  line-height: 1;
  margin: 0;
  width: 100%;
  row-gap: 16px;

  @include mobile {
    justify-content: space-between;
  }

  @include phone {
    justify-content: flex-start;
    flex-direction: column;
  }
}

.infoMessage {
  @include font-size($gl-font-size-base, true);
}

.infoLink {
  align-items: center;
  display: flex;
  margin-left: 10px;
  gap: 8px;
  background-color: #f2f0ee;
  padding: 6.5px 9px;
  border-radius: 4px;

  &:hover {
    background-color: $clr-gray-light;
  }

  @include phone {
    width: 100%;
    margin-left: 0;
  }
}

.infoLinkText {
  font-size: $gl-font-size-overline;
  font-weight: $gl-font-weight-regular;
  line-height: 1;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $clr-black-medium;
  margin: 0;
}
