@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.accordion {
  border-bottom: 1px solid $clr-gray-divider;
  padding: 36px 40px;

  @include phone {
    padding: 24px 12px 24px;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.accordionContent {
  overflow-wrap: break-word;
  width: 100%;
  max-width: $gl-accordion-max-width;
  max-height: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  opacity: 0;
  transition: all 0.2s ease;

  @media (min-width: $gl-breakpoint-webflow-desktop) {
    margin: 0 auto;
  }

  &.form {
    margin-top: 0 !important;
  }

  &.disableCollapse {
    padding: 0;
  }

  &.open {
    padding-top: 6px;
    opacity: 1;
    max-height: fit-content;
    margin-top: 24px !important;
  }

  &.disableCollapse {
    opacity: 1;
    max-height: fit-content;

    &.withTitle {
      padding-top: 12px;
    }
  }

  &.noTitle {
    padding-top: 0;
  }

  @include phone {
    gap: 12px;
  }

  &.withOutHelpInfoMessage {
    margin-top: 24px;
  }

  &.withoutOverflow {
    overflow: visible;
  }

  &.full {
    max-width: none;
  }
}

.toggle {
  align-items: center;
  display: flex;
  width: 100%;
  max-width: $gl-accordion-max-width;

  @media screen and (max-width: 767px) {
    flex-direction: row;
    align-items: flex-start;
  }

  @media (min-width: $gl-breakpoint-webflow-desktop) {
    margin: 0 auto;
  }

  &.withoutChildren {
    margin-bottom: 0;
    padding: 0;
  }

  &.withoutTitle {
    margin-bottom: 0;
  }
}

.toggleButton {
  align-items: center;
  display: inline-flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0;
  user-select: none;

  &.disabled {
    cursor: auto !important;
    user-select: auto !important;
  }
}

.toggleButtonTitle {
  font-size: $gl-font-size-m;
  font-weight: $gl-font-weight-medium;
  line-height: 1.25;
  margin: 0;
  text-align: left;
}

.toggleIcon {
  color: $clr-black;
}

.toggleIconButton {
  margin: 0;
  border-radius: 6px;
  &:hover {
    background-color: $clr-background-light;
  }
}

.toggleRight {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin: 0 8px;

  // @media screen and (max-width: 767px) {
  //   margin-left: 5px;
  //   margin-top: 12px;
  // }
  @include phone {
    margin: 0;
  }
}

.infoLink {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  background-color: #f2f0ee;
  padding: 4px;
  border-radius: 4px;

  &:hover {
    background-color: $clr-gray-light;
  }
}

.accordionTitleAndRadioButton {
  display: flex;
  flex: 1;

  @include phone {
    flex-direction: column;
    gap: 16px;
  }
}

.alignCenter {
  margin: 0 auto;
}

.contactHelpInfoMessageContainer {
  max-width: 474px !important;

  a {
    font-weight: $gl-font-weight-medium;
    cursor: pointer;

    &:hover {
      color: $clr-green;
    }
  }
}

.titleAndCopyIconContainer {
  position: relative;
}

.copyLink {
  opacity: 0;
  position: absolute;
  left: -30px;
  top: -1px;
  color: $clr-primary-200;
  padding-right: 10px;

  &.showIcon {
    opacity: 1;
  }

  &:hover {
    color: $clr-primary-500;
    cursor: pointer;
  }

  @include phone {
    left: 0;
    position: static;
    padding-right: 0;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}
