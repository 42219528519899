@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.appToClone {
  align-items: center;
  border-bottom: none !important;
  display: flex;
  margin-bottom: 0 !important;
  max-width: 600px;
}

.appToCloneImage {
  margin-right: 12px;
}

.iconLink {
  margin-left: 4px;
  margin-bottom: -3px;
}

.standaloneButtonsItem {
  font-weight: $gl-font-weight-semibold;

  &.cloneButton {
    background-color: #1a100b;
    color: white;
  }

  &.cloneButton:hover {
    background-color: #0e2538;

    &.disabled:hover {
      background-color: #1a100b;
    }
  }
}

.overviewButtons {
  max-width: $gl-subsection-max-width;
}

.newAppForm {
  max-width: 500px;
}

.accordionNewApp {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  overflow: visible !important;
}

.button {
  width: 240px;
}

.copyButton {
  margin-left: 4px;
  vertical-align: middle;
  margin-top: -3px;
  padding: 6px;
  color: $clr-primary-500;
}

.url {
  color: $clr-primary-500;
  font-weight: $gl-font-weight-regular !important;
  display: inline;
  text-align: left;
  word-break: break-all;
}

.itemContainer {
  display: inline;
  color: $clr-primary;
  text-align: left;
  word-break: break-all;
}

.dropdownContent {
  overflow: visible;
}

.organizationDropdownContainer {
  display: flex;
  gap: 16px;
}

.nameAndPackageContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.iosContainer {
  @include font-size($gl-font-size-xs);
  font-weight: $gl-font-weight-regular;
  color: $clr-black;
  display: flex;
  gap: 10px;
  align-items: center;
}

.packageText {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 193px;
}

.packageContainers {
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 100%;

  @media (max-width: $gl-accordion-max-width) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    gap: 0;
  }
}

.emailContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.emailButton {
  color: $clr-primary;
  font-weight: $gl-font-weight-medium;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $clr-green;
  }
}

.suggestedEmailContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}
