@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subsectionHeader {
  display: flex;
  border-bottom: 1px solid $clr-gray-divider;
  padding: 15px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.withInfoLink {
    padding: 10px;
  }
}

.iconLabel {
  font-size: $gl-font-size-2xs;
  color: $clr-black-medium;
  line-height: 15.62px;
  padding-top: 2px;
}

.subsection {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  column-gap: 16px;
  max-width: $gl-subsection-max-width;
  // margin-top: 24px;
  @media screen and (max-width: 767px) {
    gap: 15px;
  }

  &.inline {
    flex-direction: row;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &.withoutMargin {
    margin-top: 0;
  }
}

.subsectionContent {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &.full {
    padding: 0;
  }
}

.fillerContainer {
  flex: 1;
}

.subsectionContentContainer {
  border: 1px solid $clr-gray-divider;
  border-radius: 16px;
  flex: 1;
  overflow: hidden;

  &.fitContent {
    width: fit-content;
    max-width: 266px;
    min-width: 266px;
  }
}

.subsectionContentHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.alignCenter {
  margin: 24px auto 0;
}

.infoLink {
  align-items: center;
  display: flex;
  margin-left: 10px;
  gap: 8px;
  background-color: #f2f0ee;
  padding: 6.5px 9px;
  border-radius: 4px;

  &:hover {
    background-color: $clr-gray-light;
  }
}

.infoLinkText {
  font-size: $gl-font-size-overline;
  font-weight: $gl-font-weight-regular;
  line-height: 1;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $clr-black-medium;
  margin: 0;
}

.trashButton {
  border-radius: 6px;
  &:hover {
    background-color: $clr-gray-light;
  }
}
